<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">券信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<div class="viewTitle">基本信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>券名称:</div>
						<el-input v-model="title" placeholder="请输入券名称" style="width: 200px;" @input="handleInput"></el-input>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>券分类:</div>
						<el-select v-model="firstClass" placeholder="请选择一级分类" style="width: 200px;" clearable
							@change="handleFirstClassChange">
							<el-option v-for="item in firstClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="secondClass" placeholder="请选择二级分类" style="width: 200px;margin-left: 12px;"
							clearable v-if="firstClass != ''">
							<el-option v-for="item in secondClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt">(没找到分类?请<span @click="handleAddClassClick()">新增分类</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">券类型:</div>
						<el-radio-group v-model="ruleInfo.couponType">
							<el-radio :label="1">满减券</el-radio>
							<el-radio :label="2">现金券</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="comView" v-if="ruleInfo.couponType == 1">
					<div class="comItem">
						<div class="comItemName">满减规则:</div>
						<div style="margin-right: 4px;">满</div>
						<el-input type="number" v-model.number="ruleInfo.full" placeholder="金额" style="width: 120px;">
						</el-input>
						<div style="margin-left: 8px;margin-right: 4px;">减</div>
						<el-input type="number" v-model.number="ruleInfo.deduct" placeholder="金额" style="width: 120px;">
						</el-input>
					</div>
				</div>
				<div class="comView" v-if="ruleInfo.couponType == 2">
					<div class="comItem">
						<div class="comItemName">现金规则:</div>
						<el-input type="number" v-model.number="ruleInfo.immediateDeduct" placeholder="请输入金额" style="width: 200px;">
						</el-input>
						<el-tooltip class="item" effect="dark"
							content="例:如佩玛思特，简码设置为pm就可以直接搜索到这个商品，一个商品可多个简码，输入框顿号分隔。如佩玛思特为pm、st、pmst" placement="bottom">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">使用时间:</div>
						<el-date-picker v-model="availableTime" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</div>
				</div>
				<div class="comView">
					<div class="comItem" style="align-items: initial;height: auto;">
						<div class="comItemName">活动说明:</div>
						<el-input type="textarea" :rows="4" placeholder="请输入说明" v-model="description" style="width: 400px;" resize="none"/>
					</div>
				</div>
			</div>
			<div class="viewB">
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交</el-button>
			</div>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
	</div>
</template>

<script>
import { pinyin } from 'pinyin-pro';
	import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
	export default {
		components: {
			dialogTypeSetting
		},
		data() {
			return {
				editMsg: JSON.parse(this.$route.query.editMsg), //为null是新增  编辑跳转信息
				title: '', //商品名称
				firstClass: '', //一级分类
				firstClassList: {}, //一级分类列表
				secondClass: '', //二级分类
				secondClassList: {}, //二级分类列表
				addType: 0, //弹框类型
				nodeData: {}, //弹框内容
				dialogTypeSetting_state: false, //弹框状态
				availableTime:[],  //时间
				ruleInfo:{
					couponType: 1, //1满减  2现金
					full:0,
					deduct:0,
					immediateDeduct:0
				},
				description:'',  //描述
			}
		},
		mounted() {
			this.getProductData()
			if (this.editMsg != null) {
				this.title = this.editMsg.title;
				this.firstClass = this.editMsg.firstCategoryInfo.id;
				this.secondClass = this.editMsg.secondCategoryInfo.id;
				this.ruleInfo = {
					couponType: Number(this.editMsg.ruleInfo.couponType.id),
					full:this.editMsg.ruleInfo.full,
					deduct:this.editMsg.ruleInfo.deduct,
					immediateDeduct:this.editMsg.ruleInfo.immediateDeduct
				};
				this.availableTime = [new Date(this.editMsg.availableTime.start*1000),new Date(this.editMsg.availableTime.end*1000)];
				this.description = this.editMsg.description
			}
		},
		methods: {
			handleInput(event){
				console.log(event)
				this.simpleCode=this.toPinyin(event).replace(/\s+/g, '')
			},
			toPinyin(str){
				let pinyinArr=pinyin(str,{ pattern: 'initial' })
				return pinyinArr
			},
			//提交
			handleSubmitClick() {
				if (this.title == '') {
					this.$message({
						type: 'error',
						message: '请输入卡名称'
					});
					return
				}
				if (this.firstClass == '') {
					this.$message({
						type: 'error',
						message: '请选择一级分类'
					});
					return
				}
				if (this.secondClass == '') {
					this.$message({
						type: 'error',
						message: '请选择二级分类'
					});
					return
				}
				if(this.availableTime.length == 0){
					this.$message({
						type: 'error',
						message: '请选择时间'
					});
					return
				}
				let params = {
					title: this.title,
					ruleInfo:this.ruleInfo,
					availableTime:{
						start:new Date(this.availableTime[0]).getTime() / 1000,
						end:new Date(this.availableTime[1]).getTime() / 1000
					},
					description:this.description
				}
				//获取1级类目参数
				this.firstClassList.itemList.map((item) => {
					if (item.id == this.firstClass) {
						params.firstCategoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
				//获取2级类目参数
				this.secondClassList.itemList.map((item) => {
					if (item.id == this.secondClass) {
						params.secondCategoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
				
				if (this.editMsg != null) { //编辑
					params.virtualId = this.editMsg.virtualId;
				}
				this.$http.post("/product/virtual/coupons/update", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已提交'
						});
						sessionStorage.setItem('mark',1)
						this.$router.go(-1);
					}
				})
			},
			//1级类目变化
			handleFirstClassChange() {
				this.secondClass = '';
				this.secondClassList = [];
				this.firstClassList.itemList.map((item) => {
					if (item.id == this.firstClass) {
						this.secondClassList = item
					}
				})
			},
			//获取类目
			getProductData() {
				let params = {
					code: 'coupontype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.firstClassList = res.data;
						if (this.firstClass != '') {
							this.firstClassList.itemList.map((item) => {
								if (item.id == this.firstClass) {
									this.secondClassList = item
								}
							})
						}
					}
				})
			},
			//关闭弹框
			submit_dialogTypeSetting() {
				if (this.addType == 1) {
					this.getProductData()
				} else if (this.addType == 2) {
					this.getProductData();
				}
				this.dialogTypeSetting_state = false;
			},
			//打开弹框
			handleAddClassClick() {
				if (this.firstClass == '') {
					this.addType = 1;
					this.nodeData = this.firstClassList;
				} else {
					this.addType = 2;
					this.nodeData = this.secondClassList;
				}
				this.dialogTypeSetting_state = true;
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			background: #fff;
			height: 100%;

			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}

			.viewC {
				flex: 1;
				overflow-y: scroll;
				padding: 0 20px;
				padding-top: 20px;

				.viewTitle {
					width: 64px;
					padding-left: 9px;
					font-size: 16px;
					font-weight: bold;
					background: url(../../../assets/img/cangku/detailName.png) no-repeat;
					background-position: left top;
					background-size: 16px 16px;
					margin-bottom: 20px;
				}

				.comView {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					font-size: 14px;

					.comItem {
						display: flex;
						align-items: center;
						height: 40px;

						.comItemName {
							width: 70px;
							text-align: right;
							margin-right: 8px;

							span {
								color: #FF3939;
							}
						}
					}

					.comTxt {
						color: #666;
						margin-left: 12px;

						span {
							color: #F77E04;
							cursor: pointer;
						}
					}
				}
			}

			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}

			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
